import typographyHelpers from './typography-helpers'

const globalStyle = `
    ${typographyHelpers}

    .hc-canvas-theme-color--background {
        background: #E8E3E0;
    }

    .hc-position-relative {
        position:relative;
    }

    .hc-full-height, .hc-full-height-error {
        min-height: calc(100vh - 56px);
    }

    .hc-full-width {
        min-width: 100% !important;
    }

    .hc-z-index {
        z-index: 20 !important;
    }

    @media screen and (max-width: 1235px) {
        .hc-full-height {
            min-height: calc(100vh - 84px);
        }
    }

    .container-margin-top {
        margin-top: 56px !important;
    }
    
    @media screen and (max-width: 1235px) {
        .container-margin-top {
            margin-top: 84px !important;
        }
    }

    .hc-tb-border{
        border-bottom: 1px solid #e6e6e6 !important;
    }

    .hc-bg-none {
        background: none !important;
    }

    .hc-bs-none {
        box-shadow: none !important;
    }

    .hc-bo-none {
        border: none !important;
    }

    .hc-visibility-hidden {
        visibility: hidden;
    }

    .hc-cursor-pointer {
        cursor: pointer;
    }

    .hc-cursor-not-allowed {
        cursor: not-allowed;
    }

    .hc-disabled-link {
        cursor: default;
        pointer-events: none;
        color: #b3b3b3 !important;
    }
    .hc-cursor-default {
        cursor: default;
    }

    .hc-width-5px{
        width: 5px;
    }

    .hc-display-block {
        display: block;
    }
    html, body {
        min-height: 100vh;
        height: 0;
        -moz-osx-font-smoothing: grayscale;
    }

    .C-TableData {
        font-size: 12px;
    }
    .C-TableHeader__innerContent {
        padding-right: 0;
        font-weight: 700;
    }
    .C-ExpandableSection__Content {
        padding: 0 !important;
    }
    .C-ExpandableSection__TopRow {
        flex-direction: row-reverse;
    }
    .C-Autocomplete__Options .C-Button {
        font-size: 12px;
    }
    .C-Autocomplete .C-GridContainer {
        max-height: 100px;
        overflow: auto;
    }
    .C-Toaster {
        top: 80px !important;
        z-index: 1000;
    }
    table thead tr th {
        cursor: pointer;
    }
    .C-Toaster .C-Heading {
        text-transform: capitalize;
    }
    .max-width-250 {
        max-width: 250px;
        min-height: 36px;
    }
    .hc-pb-8x {
        padding-bottom: 300px !important;
    }
    .C-Autocomplete__Options {
        min-width: 100px;
    }
`
export default globalStyle
