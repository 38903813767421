import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider } from '@praxis/component-auth'
import { EnvProvider } from '@praxis/component-runtime-env'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { merge } from 'lodash'
import { useCallback } from 'react'
import { decodeToken } from 'react-jwt'
import { BrowserRouter as Router } from 'react-router-dom'
import { trackLogin, trackLogOut } from '../common/fireflyEvents'
import { initializeFireflySDK } from '../common/fireflyHelper'
import {
  isPOLDomain,
  removeLocalStorageItems,
  setToLocalStorage,
} from '../common/helper'
import ApiKeyInterceptor from '../components/ApiKeyInterceptor'
import AuthErrorInterceptor from '../components/AuthErrorInterceptor'
import GlobalStyles from '../components/GlobalStyles'
import DefaultLayout from '../layouts'
import { DaleProvider } from '@dale/core'
import apiConfig from '../config/apiConfig'

function App() {
  const handleLoginSuccess = useCallback((err: any, session: any) => {
    const userDir = session?.access?.dir || ''
    const tokenExpires = session?.access?.exp || ''
    const userADGroups = session?.userInfo?.memberOf || []
    const userName = session?.userInfo?.fullName || ''
    const userEmail = session?.userInfo?.email || ''
    const lanId = session?.userInfo?.lanId || ''
    const idToknenObj = JSON.parse(
      JSON.stringify(decodeToken(session?.identityToken)),
    )
    const companyName = idToknenObj?.company || ''
    const firstName = session?.userInfo?.firstName || ''
    const userDirObj = {
      value: userDir,
      expiry: tokenExpires,
      lanId,
      userName,
      userEmail,
      firstName,
      companyName,
    }
    const userADGroupsObj = {
      value: userADGroups,
      expiry: tokenExpires,
    }
    setToLocalStorage('userDir', userDirObj)
    setToLocalStorage('userADGroups', userADGroupsObj)
    trackLogin()
  }, [])

  const handleLogoutSuccess = useCallback(() => {
    removeLocalStorageItems([
      'userDir',
      'userADGroups',
      'selected_vendor',
      'external_vendor_detail',
    ])
    trackLogOut()
  }, [])

  initializeFireflySDK()
  const authURL = isPOLDomain(window.location.origin)
    ? apiConfig.auth
    : apiConfig.authInternal
  const auth = merge(authURL, {
    loginRedirect: `${window.location.origin}/auth/login`,
    logoutRedirect: `${window.location.origin}`,
  })
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: apiConfig.UI_CACHE_TIME,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <DaleProvider>
        <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
        <ApiKeyInterceptor {...apiConfig} />
        <AuthProvider
          {...auth}
          onLogin={handleLoginSuccess}
          onLogout={handleLogoutSuccess}
        >
          <AuthErrorInterceptor />
          <GlobalStyles />
          <ToastProvider location="top">
            <Router>
              <DefaultLayout apiConfig={apiConfig} />
            </Router>
          </ToastProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </DaleProvider>
    </QueryClientProvider>
  )
}

export default App
