export const operatorOptions = [
  {
    value: 'ON',
    label: 'On',
  },
  {
    value: 'BETWEEN',
    label: 'Between',
  },
  {
    value: 'ON_OR_BEFORE',
    label: 'On or Before',
  },
  {
    value: 'ON_OR_AFTER',
    label: 'On or After',
  },
]

export const dateFilters = [
  {
    value: 'TODAY',
    label: 'Today',
    applicableOperators: ['ON', 'ON_OR_BEFORE', 'ON_OR_AFTER'],
  },
  {
    value: 'YESTERDAY',
    label: 'Yesterday',
    applicableOperators: ['ON', 'ON_OR_BEFORE', 'ON_OR_AFTER'],
  },
  {
    value: 'LAST_7_DAYS',
    label: 'Last 7 Days',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_14_DAYS',
    label: 'Last 14 Days',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_28_DAYS',
    label: 'Last 28 Days',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_30_DAYS',
    label: 'Last 30 Days',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_90_DAYS',
    label: 'Last 90 Days',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'THIS_WEEK',
    label: 'This Week',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'THIS_MONTH',
    label: 'This Month',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'THIS_YEAR',
    label: 'This Year',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_WEEK',
    label: 'Last Week',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_MONTH',
    label: 'Last Month',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_SECOND_MONTH',
    label: 'Second Last Month',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_THIRD_MONTH',
    label: 'Third Last Month',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_TWO_MONTHS',
    label: 'Last 2 Months',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'LAST_YEAR',
    label: 'Last Year',
    applicableOperators: ['BETWEEN'],
  },
  {
    value: 'CUSTOM',
    label: 'Custom',
    applicableOperators: ['ON', 'ON_OR_BEFORE', 'ON_OR_AFTER', 'BETWEEN'],
  },
]

export const nonDimensionFilter: any = {
  REPORT_END_DATE: {
    name: 'Reporting End Date',
    type: 'date',
    field_name: 'REPORT_END_DATE',
  },
}
