import axios from 'axios'
import { buildURL } from '../helper'
import apiConfig from '../../config/apiConfig'

const getUserType = async () => {
  const { data } = await axios.get(
    `${buildURL('userInfo', apiConfig.campaign)}`,
  )
  return data
}

export default getUserType
