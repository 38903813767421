import { isEmpty } from 'lodash'
import { DataTableParameters } from '../api/campaign/datatable'
import { FieldGroup, SearchParameters } from '../api/campaign/search'
import { ACCOUNT, ATTRIBUTED_TRANSACTION_LOG, VENDOR } from '../constants'
import { Dimensions } from '../hooks/types'

export const validateCampaignSearchParameters = (
  fieldGroup: FieldGroup,
  parameters: SearchParameters,
) => {
  if (fieldGroup === ACCOUNT && isEmpty(parameters)) {
    return true
  }
  if (fieldGroup === ACCOUNT && parameters.aeEmails) {
    return true
  }
  if (fieldGroup === ACCOUNT && isEmpty(parameters)) {
    return true
  }
  if (fieldGroup === VENDOR) {
    return true
  }
  const { account, bpId } = parameters
  if (fieldGroup === ACCOUNT && bpId) {
    return true
  }

  if (fieldGroup !== ACCOUNT && account && account?.length > 0) {
    return true
  }
  return false
}

export const validateDataTableParameters = (
  dimensions: Dimensions,
  parameters: DataTableParameters = {} as DataTableParameters,
) => {
  if (isEmpty(parameters)) {
    return true
  }
  if (
    (dimensions.primary !== '' && dimensions.primary) ||
    parameters.reportingType === ATTRIBUTED_TRANSACTION_LOG
  ) {
    return true
  }
  return false
}
