import { DaleHeader, useDaleContext } from '@dale/core'
import { Button, Image } from '@enterprise-ui/canvas-ui-react'
import '@dale/styles'
import EnterpriseIcon, { HelpIcon } from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'
import {
  useIsFetching,
  useIsMutating,
  useQueryClient,
} from '@tanstack/react-query'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { trackLogOut } from '../../common/fireflyEvents'
import {
  getSelectedAccount,
  getFirstName,
  removeLocalStorageItems,
  setSelectedAccount as setCurrentAccount,
  getLoggedInVendorDetail,
  isPOLDomain,
} from '../../common/helper'
import {
  isTargetPlusManagedServiceUser,
  isRmsUser,
} from '../../common/userAccessHelpers'
import {
  POL_DEV_SUPPORT_LINK,
  POL_PROD_SUPPORT_LINK,
} from '../../config/appConfig'
import {
  GET_TEMPLATES_KEY,
  SIGN_OUT,
  CHOOSE_ACCOUNT,
  ACCESS_RIK_INFO,
} from '../../constants'
import useVendorOptions from '../../hooks/useVendorOptions'
import LoggedInVendorDetails from './LoggedInVendorDetails'
import { Modal as AccountSelectionModal } from '@dale/accountselection'
import { Link } from 'react-router-dom'
import apiConfig from '../../config/apiConfig'
interface Props {
  userDetails: any
}

const HeaderBlock = (props: Props) => {
  const { userDetails } = props
  const queryClient = useQueryClient()
  const auth = useAuth()
  const vendorOptions = useVendorOptions()

  const { user_type } = userDetails || {}

  const isRMSUser = !!isRmsUser(apiConfig)
  const [supportURL, setSupportURL] = useState<string>('')

  const isExternalUser = () => {
    return ['EXTERNAL_OTHERS', 'EXTERNAL_RMS', 'EXTERNAL_TARGET_PLUS'].includes(
      user_type,
    )
  }

  const { setAccounts, setSelectedAccount, selectedAccount, filteredAccounts } =
    useDaleContext()

  const signOut = useCallback(() => {
    trackLogOut()
    removeLocalStorageItems(['selected_vendor', 'external_vendor_detail'])
    auth.logout()
    queryClient.clear()
  }, [queryClient, auth])

  const currentAccount = getSelectedAccount()
  const userName = getFirstName()
  const defaultAccountSelect = !isExternalUser() && !currentAccount
  const [isSwitchAccountVisible, setSwitchAccountVisible] =
    useState<boolean>(defaultAccountSelect)

  const { pathname } = useLocation()
  const isLoading = useIsMutating() + useIsFetching()

  const domain =
    apiConfig.appEnv === 'prod' ? POL_PROD_SUPPORT_LINK : POL_DEV_SUPPORT_LINK

  let selectedVendor = isExternalUser()
    ? getLoggedInVendorDetail()
    : getSelectedAccount()

  const getRmsURL = () => {
    if (isPOLDomain(window.location.origin)) {
      return apiConfig?.rmsPolURL
    } else {
      return apiConfig?.rmsEnterpriseURL
    }
  }
  const getRmsSupportCenterURL = () => {
    if (isPOLDomain(window.location.origin)) {
      return apiConfig?.roundelHelpCenterPolURL
    } else {
      return apiConfig?.roundelHelpCenterEnterpriseURL
    }
  }

  const selectedAccountObject = useVendorOptions(true).filter(
    (vendor: any) => vendor?.id === selectedVendor?.id,
  )

  const getRmsBillingURL = () => {
    let URL = ''
    const { isTargetPlusAccount = false } = selectedAccountObject[0] || {}
    if (isRMSUser && selectedVendor?.id) {
      if (isTargetPlusAccount) {
        URL = `${getRmsURL()}/v/${selectedVendor?.id}/billing`
      } else {
        URL = `${getRmsURL()}/b/${selectedVendor?.id}/billing`
      }
    }
    return URL
  }

  const getRmsCampaignURL = () => {
    let URL = ''
    if (isRMSUser && selectedVendor?.id) {
      const { isTargetPlusAccount = false } = selectedAccountObject[0] || {}
      if (isTargetPlusAccount) {
        URL = `${getRmsURL()}/v/${selectedVendor?.id}/campaigns`
      } else {
        URL = `${getRmsURL()}/b/${selectedVendor?.id}/campaigns`
      }
    }
    return URL
  }
  useEffect(() => {
    if (Object.keys(apiConfig).length > 1) {
      let supportPath
      //check if the user is target+ managed service user
      //check if he is not part of RMS
      if (isRMSUser) {
        supportPath = `${getRmsSupportCenterURL()}${apiConfig?.supportURLs?.RMS?.help}`
      } else if (isTargetPlusManagedServiceUser(apiConfig)) {
        supportPath = `${apiConfig?.supportURLs?.targetPlus?.help}`
      } else {
        supportPath = `${domain}${apiConfig?.supportURLs?.POL?.help}`
      }
      setSupportURL(supportPath)
    }
  }, [apiConfig, isRMSUser, isTargetPlusManagedServiceUser(apiConfig)])

  useEffect(() => {
    if (!isExternalUser() && !currentAccount && vendorOptions.length > 0) {
      setSwitchAccountVisible(true)
    }
  }, [currentAccount, vendorOptions])

  useEffect(() => {
    if (vendorOptions?.length > 0) {
      setAccounts(vendorOptions)
      setSelectedAccount(currentAccount)
    }
  }, [vendorOptions])

  return (
    <DaleHeader isLoading={false}>
      <DaleHeader.SideNav>
        <DaleHeader.SideNavAccountDropdown selectedAccount={selectedAccount}>
          {filteredAccounts.map((account: any) => (
            <DaleHeader.SideNavAccountDropdownItem
              key={account.id}
              account={account}
              href="/"
              onClick={() => {
                let shouldReload = true
                const vendorDetails = getSelectedAccount()
                if (vendorDetails && vendorDetails?.id !== account?.id) {
                  shouldReload = false
                }
                setCurrentAccount(account)
                const pathUrl = window.location.href
                if (
                  pathUrl.includes('/view?t=') ||
                  pathUrl.includes('/view/')
                ) {
                  queryClient.invalidateQueries({
                    queryKey: [GET_TEMPLATES_KEY],
                  })
                  if (shouldReload) {
                    window.location.reload()
                  } else {
                    window.location.href = '/'
                  }
                } else {
                  window.location.reload()
                }
              }}
            />
          ))}
        </DaleHeader.SideNavAccountDropdown>
        {isRMSUser && (
          <DaleHeader.SideNavNavigationItem
            href={getRmsCampaignURL()}
            label="Campaigns"
          />
        )}
        <DaleHeader.SideNavNavigationItem
          href={pathname}
          label="Reports"
          isActive
        />
        {isRMSUser && (
          <DaleHeader.SideNavNavigationItem
            href={getRmsBillingURL()}
            label="Billing"
          />
        )}
      </DaleHeader.SideNav>
      <DaleHeader.Logo href="/">
        <Image
          src="/Kiosk_Logo.svg"
          alt="logo"
          aria-label="Link Roundel Kiosk Home"
          width={'177px'}
          height={'19px'}
        />
      </DaleHeader.Logo>
      <DaleHeader.AppBar>
        <DaleHeader.AccountDropdownAndNav>
          <DaleHeader.AccountDropdown selectedAccount={selectedVendor}>
            {filteredAccounts?.map((account: any) => (
              <DaleHeader.AccountDropdownItem
                key={account.id}
                account={account}
                href="/"
                onClick={() => {
                  let shouldReload = true
                  const vendorDetails = getSelectedAccount()
                  if (vendorDetails && vendorDetails?.id !== account?.id) {
                    shouldReload = false
                  }
                  setCurrentAccount(account)
                  const pathUrl = window.location.href
                  if (
                    pathUrl.includes('/view?t=') ||
                    pathUrl.includes('/view/')
                  ) {
                    queryClient.invalidateQueries({
                      queryKey: [GET_TEMPLATES_KEY],
                    })
                    if (shouldReload) {
                      window.location.reload()
                    } else {
                      window.location.href = '/'
                    }
                  } else {
                    window.location.reload()
                  }
                }}
              />
            ))}
            {!isExternalUser() && !isLoading && (
              <AccountSelectionModal
                bodyText={
                  <>
                    <div>{CHOOSE_ACCOUNT}</div>
                    <div className="hc-bg-highlight">{ACCESS_RIK_INFO}</div>
                  </>
                }
                onAccountSelect={(account: SelectOption) => {
                  setSwitchAccountVisible(false)
                  let shouldReload = true
                  const vendorDetails = getSelectedAccount()
                  if (vendorDetails && vendorDetails?.id !== account?.id) {
                    shouldReload = false
                  }
                  setCurrentAccount(account)
                  const pathUrl = window.location.href
                  if (
                    pathUrl.includes('/view?t=') ||
                    pathUrl.includes('/view/')
                  ) {
                    queryClient.invalidateQueries({
                      queryKey: [GET_TEMPLATES_KEY],
                    })
                    if (shouldReload) {
                      window.location.reload()
                    } else {
                      window.location.href = '/'
                    }
                  } else {
                    window.location.reload()
                  }
                }}
                isVisible={isSwitchAccountVisible}
                accountOptions={vendorOptions}
              />
            )}
            <LoggedInVendorDetails vendorOptions={vendorOptions} />
          </DaleHeader.AccountDropdown>
          <DaleHeader.Navigation>
            {isRMSUser && (
              <DaleHeader.NavigationItem
                href={getRmsCampaignURL()}
                label="Campaigns"
              />
            )}
            <DaleHeader.NavigationItem
              href={pathname}
              label="Reports"
              isActive
            />
            {isRMSUser && (
              <DaleHeader.NavigationItem
                href={getRmsBillingURL()}
                label="Billing"
              />
            )}
          </DaleHeader.Navigation>
        </DaleHeader.AccountDropdownAndNav>
        <DaleHeader.Tools>
          {supportURL && (
            <DaleHeader.ToolItem>
              <Button
                type="ghost"
                iconOnly
                aria-label="Link Help"
                as={Link}
                to={supportURL}
                target="_blank"
                role="link"
              >
                <EnterpriseIcon icon={HelpIcon} />
              </Button>
            </DaleHeader.ToolItem>
          )}
          <DaleHeader.ToolItem>
            <DaleHeader.ProfileDropdown name={userName}>
              <DaleHeader.ProfileDropdownItem onClick={signOut}>
                {SIGN_OUT}
              </DaleHeader.ProfileDropdownItem>
            </DaleHeader.ProfileDropdown>
          </DaleHeader.ToolItem>
        </DaleHeader.Tools>
      </DaleHeader.AppBar>
    </DaleHeader>
  )
}
export default HeaderBlock
