import { Layout, Modal } from '@enterprise-ui/canvas-ui-react'
import styled from 'styled-components'

export const StyledAccountSelector = styled(Modal)`
  z-index: 99 !important;

  .C-Modal__container {
    max-width: 40vw;
  }

  .C-Autocomplete__Options {
    max-height: 125px;
  }

  h1.C-Heading {
    padding-left: var(--canvas-spacing--lg) !important;
    padding-top: var(--canvas-spacing--lg) !important;
  }

  .C-Modal__container__content {
    padding-bottom: var(--canvas-spacing--sm) !important;
  }
`

export const StyledFeedbackForm = styled(Modal)`
  .C-FormField__Label {
    font-size: 16px;
  }

  .C-Input--type-info.isErrored {
    display: none;
  }

  .C-Autocomplete__Options {
    max-height: 125px;
  }

  h1.C-Heading {
    padding-left: var(--canvas-spacing--lg) !important;
    padding-top: var(--canvas-spacing--lg) !important;
  }

  .C-Modal__container__content {
    padding-bottom: var(--canvas-spacing--sm) !important;
  }
`
export const StyledHeaderLayout = styled(Layout.Header)`
  .userprofile_btn {
    color: #fff !important;
    background: transparent !important;
    .profileIcon {
      color: #fff;
      padding-right: 0.5em;
      &:hover {
        color: var(--canvas-background-color--interactive-hover);
      }
    }
  }
  .C-Dropdown .C-DropdownMenu {
    top: 45px;
    overflow-y: auto !important;
  }
  .new-report-btn {
    min-width: 120px;
  }
  .header-container {
    flex-wrap: nowrap;
  }
  .menu-container {
    width: calc(100vw - 490px);
    a {
      margin: 0 18px 10px 18px;
      white-space: nowrap;
      display: inline-block;
    }
  }
`
