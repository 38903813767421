export const wipConfig = {
  commonConfig: {
    derived: '',
    apiKey: 'afb5939e20276ad77dcaff1f77e4a8677aafa988',
    appEnv: 'dev',
    crossAdvertiserID: 999999994,
    supportURL: 'https://xyc.partnersonline.com/page/help/MzQ3',
    supportURLs: {
      RMS: {
        help: '/categories/Kiosk/Overview_of_Kiosk',
        ticket: '/contact?productType=Kiosk',
        termsOfUseLink: '/terms',
      },
      POL: {
        help: 'page/library/RIK/applications/Application%20Overview',
        ticket: 'page/help/MzQ3',
        termsOfUseLink: 'sites/ogrp-tts-polapplications/Pages/3649.aspx',
      },
      targetPlus: {
        help: 'https://plus.perf.target.com/article/000175935',
        ticket: '',
        termsOfUseLink: '',
      },
    },
    firefly_url:
      'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    termsOfUseLink: 'sites/ogrp-tts-polapplications/Pages/3649.aspx',
    UI_CACHE_TIME: 300000,
    metricsAdminGroup: 'APP-RIK-METRICS-ADMIN-STAGE',
    templateAdminGroup: 'APP-RIK-TEMPLATE-ADMIN-STAGE',
    rikAdminSettingsGroup: 'APP-RIK-UI-DEV-ADMIN',
    rikLimitedAccessGroup: 'APP-RIK-LIMITED-USER-STAGE',
    targetPlusManagedService: 'APP-SMS',
    rmsAccessGroups:
      'APP-OAUTH2-RMS-Prod-Read, APP-OAUTH2-RMS-Prod-Admin, APP-OAUTH2-RMS-Prod-Update, APP-OAUTH2-RMS-Prod-Update, APP-OAUTH2-RMS-Read-Prod',
    rmsEnterpriseURL: 'https://selfserviceapp-wip.dev.target.com',
    rmsPolURL: 'https://selfserviceapp-wip.dev.target.com',
    roundelHelpCenterEnterpriseURL: 'https://roundelsupportcenter.perf.target.com',
    roundelHelpCenterPolURL: 'https://roundelsupportcenter.perf.partnersonline.com',
    auth: {
      clientId: 'rik_npe_im',
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html',
    },
    authInternal: {
      clientId: 'rik_npe_im',
      authorizationUrl:
        'https://oauth.iam.perf.target.com/auth/oauth/v2/tgt/authorize/ad-pol/1',
      logoutUrl:
        'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    },
    crossAdvertiser: {
      offlineExportRecordLimit: 1500000,
      carId: 999999994,
    },
    educationAlerts: {
      version: 'v1',
      message: {
        internalMsg:
          "<strong>Introducing the 'Mapping Info' sidebar tab in Kiosk! </strong><br> This new tab, available to all internal users, can be used to understand how accounts are mapped (or not mapped) in Kiosk. The 'Vendor Mapping' table, for example, can be used for determining whether or not an account is already mapped for access in Kiosk or for troubleshooting account naming nuances!",
        msMsg:
          "<strong>Refreshed Training Documentation Available! </strong><br> Roundel Kiosk recently updated the training documentation within Partners Online (POL) and can be viewed by clicking on the Help Icon in the upper right corner of the screen. Whether you're new to the tool or need a quick refresher, our step-by-step guides and tips are here to support you. Explore the updated materials today to enhance your campaign reporting experience! ",
        rmsMsg:
          "<strong><i>NEW Feature</i> - Instantly view reports for <i>any</i> campaign, Live or Completed - right from the dashboard - that too in a <i>single</i> click!</strong><p>By default, these single click reports are generated for your campaign of choice using one of our recommended 'Roundel Global Templates' but you could use the 'Customize' button in the section below to select a preferred template of your choice. Preferred templates can be at an individual Campaign level or even at a Campaign Category level! <br />Try it out for your self by clicking on any campaign name below!</p>",
      },
      maxCount: 10,
    },
    educationAlerts2: {
      version: 'v1',
      message:
        "<strong>Objective Based Buying (OBB) Features Available in Roundel Kiosk, Exclusive to Alpha Partners!</strong><br> Take advantage of OBB-specific metrics, dimensions, filters, and templates that support a more streamlined approach for campaign analysis based on objectives. Filter and view alpha campaigns by awareness, consideration, and conversion objectives, with historical or non-OBB campaigns categorized as ‘Independent’.</br>Your feedback as an alpha partner is important to us. While exploring feature enhancements throughout Roundel Kiosk’s Homepage Dashboard and Report Views, please submit your feedback or recommendations using the green 'Feedback' button at the bottom right of the page.",
      maxCount: 10,
    },
    alphaList: [
      '3178',
      '3491',
      '88888888888',
      '5804',
      '34025',
      '32499',
      '4364',
      '3396',
      '3821',
      '4948',
      '21762',
      '58460',
      '4365',
      '6900',
      '3595',
      '4141',
      '5077',
      '6382',
      '6545',
      '66911',
    ],
    upSellMetricsList: [
      'INVENTORY_AVAILABILITY',
      'ACTIVE_AUDIENCE_SIZE',
      'LIKELY_TO_DELIVER_1',
      'LIKELY_TO_DELIVER_2',
      'PLATFORM_ROAS',
      'CAMPAIGN_ROAS',
      'CAMPAIGN_PACING_PERCENTAGE',
    ],
  },
  internalUserConfig: {
    atl: {
      host: 'https://rikrmsatl-wip.dev.target.com/roundel_rik_campaigns_attributed_transaction_log_data/',
      paths: {
        aggregation: 'v1/atl_data',
        aggregateStatuses: 'v1/atl_data_status',
      },
    },
    campaign: {
      host: 'https://rikcampaignapi-wip.dev.target.com/roundel_rik_campaigns/',
      paths: {
        aggregateStatuses: 'v1/aggregate_statuses',
        overview: 'v1/overviews',
        overviewV2: 'v2/overviews',
        aggregation: 'v2/aggregate',
        search: 'v1/search',
        searchV2: 'v2/search',
        load: 'v2/cache_loads',
        metaData: 'v2/metadata/MEDIA_NAME',
        dimensionsMetaData: 'v2/metadata',
        notifications: 'v1/notices',
        userInfo: 'v1/user_info',
        crossAdvertiserSearch: 'v2/cross_advertiser_search',
        preAggregateRowCount: 'v1/pre_aggregate_row_counts',
      },
    },
    campaignTemplate: {
      host: 'https://rikviewapi-wip.dev.target.com/roundel_rik_template_mappings/v1',
      paths: {
        campaignTemplate: '/template_mappings',
        globalTemplateMap: '/vendor_template_mappings',
      },
    },
    cloudStorage: {
      host: 'https://rikcloudstorageapi-wip.dev.target.com/roundel_rik_cloud_storage_data/v1',
      paths: {
        bucketDetails: '/bucket_details',
        awsRegion: '/aws_regions',
      },
    },
    customMetrics: {
      host: 'https://rikviewapi-wip.dev.target.com/roundel_rik_custom_metrics/v1',
      paths: {
        metrics: '/metrics',
      },
    },
    export: {
      host: 'https://rikviewexportservice-wip.dev.target.com/roundel_rik_campaigns_data/v1',
      paths: {
        directExport: '/exports',
        dashboardExport: '/dashboard_exports',
        exportStatus: '/export_statuses',
      },
    },
    feedback: {
      host: 'https://rikfeedbackapi-wip.dev.target.com/roundel_rik_feedbacks/v1',
      paths: {
        feedback: '/',
      },
    },
    feedbackAPI: {
      host: 'https://rikfeedbackapi-wip.dev.target.com/roundel_rik_email_preferences/v1',
      paths: {
        emailAlertTracking: '/email_alerts',
        notificationPreference: '',
      },
    },
    inflightIncrementalSummary: {
      host: 'https://upsell.dev.target.com/display_upsell/',
      paths: {
        aggregation: 'v1/aggregate',
        aggregateStatuses: 'v1/aggregate_statuses',
        metaData: 'v1/metadata',
      },
    },
    itemPerformance: {
      host: 'https://rikitemperformanceapi-wip.dev.target.com/roundel_rik_campaigns_item_performance_data/',
      paths: {
        aggregation: 'v1/aggregate',
        load: 'v1/cache_loads',
        tcinMetaData: 'v2/tcin_metadata',
        metaData: 'v2/metadata/MEDIA_NAME',
        aggregateStatuses: 'v1/aggregate_statuses',
      },
    },
    metrics: {
      host: 'https://rikviewapi-wip.dev.target.com/roundel_rik_metrics/v1',
      paths: {
        metrics: '/metrics',
      },
    },
    modelEnablement: {
      host: 'https://modelenablementapi-wip.dev.target.com/roundel_rik_campaign_model_enablement_data/v1',
      paths: {
        aggregation: '/aggregate',
        load: '/cache_loads',
      },
    },
    modelEnablementExport: {
      host: 'https://exportmodelenablement-wip.dev.target.com/roundel_rik_campaign_model_enablement_data_exports',
      paths: {
        cloudExport: '/v1/exports',
        tossExport: '/v1/toss_export_requests',
        tossDownload: '/v1/toss_download',
      },
    },
    scheduleReports: {
      host: 'https://rikschedulingapi-wip.dev.target.com/roundel_rik_schedules/v1',
      paths: {
        scheduleReports: '/report_schedules',
      },
    },
    sharepoint: {
      host: 'https://rikadminapi-wip.dev.target.com/roundel_rik_admins/v1',
      paths: {
        sharepoint: '/admin_configs',
      },
    },
    targetPlusSupportURL: {
      paths: '',
    },
    userMappings: {
      host: 'https://vendorsmapping-wip.dev.target.com/rik_users/v1',
      paths: {
        userMappings: '/users',
        updateUserMapping: '/user_vendor_mappings',
        criteoAccounts: '/rms_accounts',
      },
    },
    vendorMappings: {
      host: 'https://vendorsmapping-wip.dev.target.com/roundel_rik_vendor_mappings/v1',
      paths: {
        vendors: '',
        vendorMappings: '',
        updateVendorMapping: '',
        salesforceAccounts: '/salesforce_accounts',
      },
    },
    upsellQualifier: {
      host: 'https://rikadminapi-wip.dev.target.com/roundel_rik_admins/v1',
      paths: {
        upsellQualifier: '/upsell_qualifier',
      },
    },
    viewAPI: {
      host: 'https://rikviewapi-wip.dev.target.com/roundel_rik_views/v1',
      paths: {
        dimensions: '/dimensions',
        columns: '/lovs/columns',
        templates: '/templates',
        templateName: '/templates/template_name',
        checkDuplicate: '/templates/duplicate/template_name',
        views: '/views',
        pinnedView: '/pinned_views',
      },
    },
  },
  externalUserConfig: {
    atl: {
      host: 'https://rikrmsatl-wip.dev.target.com/roundel_rik_campaigns_attributed_transaction_log_data/',
      paths: {
        aggregation: 'v1/atl_data',
        aggregateStatuses: 'v1/atl_data_status',
      },
    },
    campaign: {
      host: 'https://rikcampaignapi-wip.dev.target.com/roundel_rik_campaigns/',
      paths: {
        aggregateStatuses: 'v1/aggregate_statuses',
        overview: 'v1/overviews',
        overviewV2: 'v2/overviews',
        aggregation: 'v2/aggregate',
        search: 'v1/search',
        searchV2: 'v2/search',
        load: 'v2/cache_loads',
        metaData: 'v2/metadata/MEDIA_NAME',
        dimensionsMetaData: 'v2/metadata',
        notifications: 'v1/notices',
        userInfo: 'v1/user_info',
      },
    },
    campaignTemplate: {
      host: 'https://rikviewapi-wip.dev.target.com/roundel_rik_template_mappings/v1',
      paths: {
        campaignTemplate: '/template_mappings',
        globalTemplateMap: '/vendor_template_mappings',
      },
    },
    cloudStorage: {
      host: 'https://rikcloudstorageapi-wip.dev.target.com/roundel_rik_cloud_storage_data/v1',
      paths: {
        bucketDetails: '/bucket_details',
        awsRegion: '/aws_regions',
      },
    },
    export: {
      host: 'https://rikviewexportservice-wip.dev.target.com/roundel_rik_campaigns_data/v1',
      paths: {
        directExport: '/exports',
        dashboardExport: '/dashboard_exports',
        exportStatus: '/export_statuses',
      },
    },
    feedback: {
      host: 'https://rikfeedbackapi-wip.dev.target.com/roundel_rik_feedbacks/v1',
      paths: {
        feedback: '/',
      },
    },
    feedbackAPI: {
      host: 'https://rikfeedbackapi-wip.dev.target.com/roundel_rik_email_preferences/v1',
      paths: {
        emailAlertTracking: '/email_alerts',
        notificationPreference: '',
      },
    },
    itemPerformance: {
      host: 'https://rikitemperformanceapi-wip.dev.target.com/roundel_rik_campaigns_item_performance_data/',
      paths: {
        aggregation: 'v1/aggregate',
        load: 'v1/cache_loads',
        tcinMetaData: 'v2/tcin_metadata',
        metaData: 'v2/metadata/MEDIA_NAME',
        aggregateStatuses: 'v1/aggregate_statuses',
      },
    },
    metrics: {
      host: 'https://rikviewapi-wip.dev.target.com/roundel_rik_metrics/v1',
      paths: {
        metrics: '/metrics',
      },
    },
    modelEnablement: {
      host: 'https://modelenablementapi-wip.dev.target.com/roundel_rik_campaign_model_enablement_data/v1',
      paths: {
        aggregation: '/aggregate',
        load: '/cache_loads',
      },
    },
    modelEnablementExport: {
      host: 'https://exportmodelenablement-wip.dev.target.com/roundel_rik_campaign_model_enablement_data_exports',
      paths: {
        cloudExport: '/v1/exports',
        tossExport: '/v1/toss_export_requests',
        tossDownload: '/v1/toss_download',
      },
    },
    scheduleReports: {
      host: 'https://rikschedulingapi-wip.dev.target.com/roundel_rik_schedules/v1',
      paths: {
        scheduleReports: '/report_schedules',
      },
    },
    sharepoint: {
      host: 'https://rikadminapi-wip.dev.target.com/roundel_rik_admins/v1',
      paths: {
        sharepoint: '/admin_configs',
      },
    },
    targetPlusSupportURL: {
      paths: '',
    },
    viewAPI: {
      host: 'https://rikviewapi-wip.dev.target.com/roundel_rik_views/v1',
      paths: {
        dimensions: '/dimensions',
        columns: '/lovs/columns',
        templates: '/templates',
        templateName: '/templates/template_name',
        checkDuplicate: '/templates/duplicate/template_name',
        views: '/views',
        pinnedView: '/pinned_views',
      },
    },
  },
}
