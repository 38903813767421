import axios from 'axios'
import {
  getBpIdParam,
  getSelectedAccount,
  isCrossAdvertiserAccount,
} from '../../common/helper'
import { buildURL } from '../helper'
import apiConfig from '../../config/apiConfig'

export type FieldGroup = 'ACCOUNT' | 'CAMPAIGN' | 'BRAND' | 'VENDOR'

export interface SearchParameters {
  aeEmails?: string[]
  amEmails?: string[]
  taEmails?: string[]
  levelOfServices?: string[]
  divisions?: string[]
  verticals?: string[]
  teams?: string[]
  tpaSae?: string[]
  account?: string[]
  brands?: string[]
  startDate?: string
  endDate?: string
  reportEndDate?: string
  bpId?: string
  type?: string
  campaignCategories?: string[]
  campaignTypes?: string[]
  carBrands?: string[]
  carCampaignStatus?: string[]
}
const convertDateFormates = (obj: any) => {
  return obj !== '' && obj?.date_operator ? obj : undefined
}

const ifNotEmpty = (arr: any) => {
  if (arr?.length > 0) {
    return arr
  }
  return undefined
}

const campaignSearch = async (
  fieldGroup: FieldGroup,
  {
    aeEmails,
    amEmails,
    taEmails,
    levelOfServices,
    divisions,
    verticals,
    teams,
    tpaSae,
    account = [],
    brands,
    startDate,
    endDate,
    reportEndDate,
    bpId,
    type,
    campaignCategories,
    campaignTypes,
    carBrands,
    carCampaignStatus,
  }: SearchParameters,
) => {
  const bpIdParam = bpId ? getBpIdParam() : ''
  const selectedAccount = getSelectedAccount()
  const bodyParams: any = {
    field_group: fieldGroup,
    account_ids: account?.length > 0 ? account : undefined,
    brand_names: ifNotEmpty(brands),
    start_date_filter: convertDateFormates(startDate),
    end_date_filter: convertDateFormates(endDate),
    reporting_completion_date_filter: convertDateFormates(reportEndDate),
    report_type: type,
    campaign_categories: ifNotEmpty(campaignCategories),
    ae_emails: ifNotEmpty(aeEmails),
    am_emails: ifNotEmpty(amEmails),
    ta_emails: ifNotEmpty(taEmails),
    level_of_services: ifNotEmpty(levelOfServices),
    divisions: ifNotEmpty(divisions),
    verticals: ifNotEmpty(verticals),
    teams: ifNotEmpty(teams),
    tpa_sae: ifNotEmpty(tpaSae),
    brands:
      selectedAccount && isCrossAdvertiserAccount(apiConfig)
        ? ifNotEmpty(carBrands)
        : ifNotEmpty(brands),
    campaign_types: ifNotEmpty(campaignTypes),
    flight_status: ifNotEmpty(carCampaignStatus),
  }
  if (fieldGroup === 'BRAND' && isCrossAdvertiserAccount(apiConfig)) {
    return
  }
  const apiURL =
    fieldGroup === 'VENDOR' || !isCrossAdvertiserAccount(apiConfig)
      ? 'searchV2'
      : 'crossAdvertiserSearch'
  const { data } = await axios.post(
    `${buildURL(apiURL, apiConfig.campaign)}${bpIdParam}`,
    bodyParams,
  )
  return data
}

export default campaignSearch
