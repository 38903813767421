import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { buildURL } from '../helper'
import { getBpIdParam } from '../../common/helper'

const saveFeedback = async (requestObject: any) => {
  const bpIdParam = getBpIdParam()
  const { status } = await axios.post(
    `${buildURL('feedback', apiConfig.feedback)}${bpIdParam}`,
    requestObject,
  )
  return status
}

export default saveFeedback
