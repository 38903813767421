import { getUserSpecificConfig } from '../common/helper'
import { devConfig } from './devConfig'
import { prodConfig } from './prodConfig'
import { wipConfig } from './wipConfig'

const env = process.env.REACT_APP_ENV
let envConfig: any = prodConfig

switch (env) {
  case 'wip':
    envConfig = wipConfig
    break
  case 'dev':
    envConfig = devConfig
    break
  case 'prod':
    envConfig = prodConfig
    break
  default:
    envConfig = wipConfig
}

const apiConfig = getUserSpecificConfig(envConfig)

export default apiConfig
