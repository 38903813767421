import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { buildURL } from '../helper'

const getSharepointData = async (config_name: string) => {
  const { data } = await axios.get(
    buildURL('sharepoint', apiConfig.sharepoint),
    {
      params: { config_name: config_name },
    },
  )
  return data
}

export default getSharepointData
